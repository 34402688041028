import React from "react";
import { NavLink } from "react-router-dom";
import {
  faFacebook,
  faLinkedin,
  faTiktok,
  faInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/logo.png";

const Footer = () => {
  const privacyPolicy =
    process.env.REACT_APP_ENV === "production"
      ? process.env.REACT_APP_PRIVACY_PROD
      : process.env.REACT_APP_PRIVACY_DEV;

  const termsAndConditions =
    process.env.REACT_APP_ENV === "production"
      ? process.env.REACT_APP_TERMS_LINK_PROD
      : process.env.REACT_APP_TERMS_LINK_DEV;

  return (
    <div className="justify-center items-center  self-stretch w-screen  bg-zinc-100 px-5  bottom-0 md:flex md:flex-col">
      <div className="items-center justify-center self-center w-full md:w-[95%]   py-3 overflow-hidden md:flex md:justify-between md:mr-40">
        <img
          loading="lazy"
          src={logo}
          className="md:hidden w-[5.3475rem] h-[1.65613rem] mb-0"
          alt="luupli logo"
        />
        <p className=" text-blue-gray-600 text-[0.9715rem] md:items-start md:text-left md:font-['Inter'] md:mx-8 md:pl-10 md:justify-start">
          @ 2024 luupli inc. All rights reserved.
        </p>
        <div className=" flex flex-row gap-5 w-[95%] md:w-[40%] md:justify-end ">
          <nav className=" justify-between items-start  self-stretch flex w-[40%] md:justify-between md:gap-2 mt-1 md:mt-3 md:mr-24  lg:mr-10 md:font-['Inter'] no-underline">
            <a
              href={termsAndConditions}
              target="_blank"
              rel="noopener noreferrer"
              className="text-slate-600 text-[0.80075rem]  self-stretch no-underline cursor-pointer hover:underline"
            >
              Terms
            </a>
            <a
              href={privacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
              className="text-slate-600 text-[0.80075rem]  self-stretch no-underline cursor-pointer hover:underline"
            >
              Privacy
            </a>
            <NavLink
              to="/contact"
              className="text-slate-600 text-[0.80075rem]  self-stretch no-underline cursor-pointer hover:underline"
            >
              Contact
            </NavLink>
          </nav>
          <div className="items-center justify-between flex flex-row w-[45%] md:justify-end md:gap-x-5 mr-5 md:mr-0 md:mt-3">
            <a
              href="https://web.facebook.com/profile.php?id=100095175349473"
              target="_blank"
              rel="noopener noreferrer"
              className=" text-current"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                className="relative w-6 h-6 hover:scale-110 cursor-pointer"
              />
            </a>
            <a
              href="https://twitter.com/luupli"
              target="_blank"
              rel="noopener noreferrer"
              className=" text-current"
            >
              <FontAwesomeIcon
                icon={faXTwitter}
                className="relative w-6 h-6 hover:scale-110 cursor-pointer"
              />
            </a>
            <a
              href="https://Instagram.com/luuplihq/"
              target="_blank"
              rel="noopener noreferrer"
              className=" text-current"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                className="relative w-6 h-6 hover:scale-110 cursor-pointer"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/luupli/"
              target="_blank"
              rel="noopener noreferrer"
              className=" text-current"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                className="relative w-6 h-6 hover:scale-110 cursor-pointer"
              />
            </a>
            <a
              href="https://www.tiktok.com/@luupli"
              target="_blank"
              rel="noopener noreferrer"
              className=" text-current"
            >
              <FontAwesomeIcon
                icon={faTiktok}
                className="relative w-6 h-6 hover:scale-110 cursor-pointer"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
